import { default as energy_45green_45practices0XOFvx5GsKMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue?macro=true";
import { default as fun_45part_45journeyJrKo4JgI24Meta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue?macro=true";
import { default as homesafe_45colorado_45master_45buildercH5vVdAQ2dMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue?macro=true";
import { default as indexH0QHKTCH92Meta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45teampmgcM4QxFnMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as preferred_45lenderwfEyVpF8JJMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue?macro=true";
import { default as realtor_45resourcesKtwIViWouMMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue?macro=true";
import { default as _91post_93SaNSAv6uFIMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as index7HuxcKLXjJMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexQw5GWhUEJaMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93HhCNtAFa8RMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexZZFpCjAPVtMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as central_45park_45special_45offerXmUaBjLQkQMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/central-park-special-offer.vue?macro=true";
import { default as ask_45a_45questionTzSvkwsMHVMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitiespSSL2XYHvJMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faqbE8bR9eZlJMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourcesk0bhDHSzkzMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexD6fvXBSpaVMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locationsSJ9ywhY3VKMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as current_45offersDKWXPcLd4SMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexRmVQmxBpRoMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexGkgdsXkPZSMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexLTaauQxtXlMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as indexITBkvl0jbIMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue?macro=true";
import { default as indexuRYIOEc5ZxMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue?macro=true";
import { default as indexmhViMjFSBrMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue?macro=true";
import { default as indexHPO9PY7daoMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/index.vue?macro=true";
import { default as knolls_45hoa_45informationfg3O2d8WqNMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue?macro=true";
import { default as land_45acquisitionmjFMPx0ntdMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as brents_45placeZLY87gxNtiMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue?macro=true";
import { default as experience_45lower_45maintenanceoZgQmBhsGoMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue?macro=true";
import { default as _91testimonial_93AEon2QZ3sXMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexVzOzykdPKeMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as index6DaAJLBT3GMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as whats_45lifefullnesssVSo9jZBKkMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue?macro=true";
import { default as _91_46_46_46slug_93x5PWtniWBKMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as my_45favoriteslVf24aLvScMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45search9Sx0rfUeKsMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as easyhouseEC6SVyKSDpMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededitionnmuRoyMdG2Meta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottage3Sdc5aAD6TMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as index4TF32LYWOzMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as our_45neighborhoodsUujp9GSZYeMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue?macro=true";
import { default as partner_45with_45usJMCl3fxBxsMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue?macro=true";
import { default as privacytermsF3KhGQdaMGMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as sandboxK0aYJOVcPwMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as _91_46_46_46slug_93BRNgPoytmVMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue?macro=true";
import { default as component_45stubb3opEZ9TwiMeta } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubb3opEZ9Twi } from "/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: energy_45green_45practices0XOFvx5GsKMeta?.name ?? "about-us-energy-green-practices",
    path: energy_45green_45practices0XOFvx5GsKMeta?.path ?? "/about-us/energy-green-practices",
    meta: energy_45green_45practices0XOFvx5GsKMeta || {},
    alias: energy_45green_45practices0XOFvx5GsKMeta?.alias || [],
    redirect: energy_45green_45practices0XOFvx5GsKMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue").then(m => m.default || m)
  },
  {
    name: fun_45part_45journeyJrKo4JgI24Meta?.name ?? "about-us-fun-part-journey",
    path: fun_45part_45journeyJrKo4JgI24Meta?.path ?? "/about-us/fun-part-journey",
    meta: fun_45part_45journeyJrKo4JgI24Meta || {},
    alias: fun_45part_45journeyJrKo4JgI24Meta?.alias || [],
    redirect: fun_45part_45journeyJrKo4JgI24Meta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue").then(m => m.default || m)
  },
  {
    name: homesafe_45colorado_45master_45buildercH5vVdAQ2dMeta?.name ?? "about-us-homesafe-colorado-master-builder",
    path: homesafe_45colorado_45master_45buildercH5vVdAQ2dMeta?.path ?? "/about-us/homesafe-colorado-master-builder",
    meta: homesafe_45colorado_45master_45buildercH5vVdAQ2dMeta || {},
    alias: homesafe_45colorado_45master_45buildercH5vVdAQ2dMeta?.alias || [],
    redirect: homesafe_45colorado_45master_45buildercH5vVdAQ2dMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue").then(m => m.default || m)
  },
  {
    name: indexH0QHKTCH92Meta?.name ?? "about-us",
    path: indexH0QHKTCH92Meta?.path ?? "/about-us",
    meta: indexH0QHKTCH92Meta || {},
    alias: indexH0QHKTCH92Meta?.alias || [],
    redirect: indexH0QHKTCH92Meta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45teampmgcM4QxFnMeta?.name ?? "about-us-our-team",
    path: our_45teampmgcM4QxFnMeta?.path ?? "/about-us/our-team",
    meta: our_45teampmgcM4QxFnMeta || {},
    alias: our_45teampmgcM4QxFnMeta?.alias || [],
    redirect: our_45teampmgcM4QxFnMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: preferred_45lenderwfEyVpF8JJMeta?.name ?? "about-us-preferred-lender",
    path: preferred_45lenderwfEyVpF8JJMeta?.path ?? "/about-us/preferred-lender",
    meta: preferred_45lenderwfEyVpF8JJMeta || {},
    alias: preferred_45lenderwfEyVpF8JJMeta?.alias || [],
    redirect: preferred_45lenderwfEyVpF8JJMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue").then(m => m.default || m)
  },
  {
    name: realtor_45resourcesKtwIViWouMMeta?.name ?? "about-us-realtor-resources",
    path: realtor_45resourcesKtwIViWouMMeta?.path ?? "/about-us/realtor-resources",
    meta: realtor_45resourcesKtwIViWouMMeta || {},
    alias: realtor_45resourcesKtwIViWouMMeta?.alias || [],
    redirect: realtor_45resourcesKtwIViWouMMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue").then(m => m.default || m)
  },
  {
    name: _91post_93SaNSAv6uFIMeta?.name ?? "blog-post",
    path: _91post_93SaNSAv6uFIMeta?.path ?? "/blog/:post()",
    meta: _91post_93SaNSAv6uFIMeta || {},
    alias: _91post_93SaNSAv6uFIMeta?.alias || [],
    redirect: _91post_93SaNSAv6uFIMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: index7HuxcKLXjJMeta?.name ?? "blog-category-category",
    path: index7HuxcKLXjJMeta?.path ?? "/blog/category/:category()",
    meta: index7HuxcKLXjJMeta || {},
    alias: index7HuxcKLXjJMeta?.alias || [],
    redirect: index7HuxcKLXjJMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexQw5GWhUEJaMeta?.name ?? "blog",
    path: indexQw5GWhUEJaMeta?.path ?? "/blog",
    meta: indexQw5GWhUEJaMeta || {},
    alias: indexQw5GWhUEJaMeta?.alias || [],
    redirect: indexQw5GWhUEJaMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93HhCNtAFa8RMeta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_93HhCNtAFa8RMeta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93HhCNtAFa8RMeta || {},
    alias: _91_46_46_46slug_93HhCNtAFa8RMeta?.alias || [],
    redirect: _91_46_46_46slug_93HhCNtAFa8RMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexZZFpCjAPVtMeta?.name ?? "campaigns",
    path: indexZZFpCjAPVtMeta?.path ?? "/campaigns",
    meta: indexZZFpCjAPVtMeta || {},
    alias: indexZZFpCjAPVtMeta?.alias || [],
    redirect: indexZZFpCjAPVtMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: central_45park_45special_45offerXmUaBjLQkQMeta?.name ?? "central-park-special-offer",
    path: central_45park_45special_45offerXmUaBjLQkQMeta?.path ?? "/central-park-special-offer",
    meta: central_45park_45special_45offerXmUaBjLQkQMeta || {},
    alias: central_45park_45special_45offerXmUaBjLQkQMeta?.alias || [],
    redirect: central_45park_45special_45offerXmUaBjLQkQMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/central-park-special-offer.vue").then(m => m.default || m)
  },
  {
    name: ask_45a_45questionTzSvkwsMHVMeta?.name ?? "contact-us-ask-a-question",
    path: ask_45a_45questionTzSvkwsMHVMeta?.path ?? "/contact-us/ask-a-question",
    meta: ask_45a_45questionTzSvkwsMHVMeta || {},
    alias: ask_45a_45questionTzSvkwsMHVMeta?.alias || [],
    redirect: ask_45a_45questionTzSvkwsMHVMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunitiespSSL2XYHvJMeta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunitiespSSL2XYHvJMeta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunitiespSSL2XYHvJMeta || {},
    alias: career_45opportunitiespSSL2XYHvJMeta?.alias || [],
    redirect: career_45opportunitiespSSL2XYHvJMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: faqbE8bR9eZlJMeta?.name ?? "contact-us-faq",
    path: faqbE8bR9eZlJMeta?.path ?? "/contact-us/faq",
    meta: faqbE8bR9eZlJMeta || {},
    alias: faqbE8bR9eZlJMeta?.alias || [],
    redirect: faqbE8bR9eZlJMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: homeowner_45resourcesk0bhDHSzkzMeta?.name ?? "contact-us-homeowner-resources",
    path: homeowner_45resourcesk0bhDHSzkzMeta?.path ?? "/contact-us/homeowner-resources",
    meta: homeowner_45resourcesk0bhDHSzkzMeta || {},
    alias: homeowner_45resourcesk0bhDHSzkzMeta?.alias || [],
    redirect: homeowner_45resourcesk0bhDHSzkzMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: indexD6fvXBSpaVMeta?.name ?? "contact-us",
    path: indexD6fvXBSpaVMeta?.path ?? "/contact-us",
    meta: indexD6fvXBSpaVMeta || {},
    alias: indexD6fvXBSpaVMeta?.alias || [],
    redirect: indexD6fvXBSpaVMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45locationsSJ9ywhY3VKMeta?.name ?? "contact-us-our-locations",
    path: our_45locationsSJ9ywhY3VKMeta?.path ?? "/contact-us/our-locations",
    meta: our_45locationsSJ9ywhY3VKMeta || {},
    alias: our_45locationsSJ9ywhY3VKMeta?.alias || [],
    redirect: our_45locationsSJ9ywhY3VKMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: current_45offersDKWXPcLd4SMeta?.name ?? "current-offers",
    path: current_45offersDKWXPcLd4SMeta?.path ?? "/current-offers",
    meta: current_45offersDKWXPcLd4SMeta || {},
    alias: current_45offersDKWXPcLd4SMeta?.alias || [],
    redirect: current_45offersDKWXPcLd4SMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: indexRmVQmxBpRoMeta?.name ?? "events-event",
    path: indexRmVQmxBpRoMeta?.path ?? "/events/:event()",
    meta: indexRmVQmxBpRoMeta || {},
    alias: indexRmVQmxBpRoMeta?.alias || [],
    redirect: indexRmVQmxBpRoMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGkgdsXkPZSMeta?.name ?? "events",
    path: indexGkgdsXkPZSMeta?.path ?? "/events",
    meta: indexGkgdsXkPZSMeta || {},
    alias: indexGkgdsXkPZSMeta?.alias || [],
    redirect: indexGkgdsXkPZSMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexLTaauQxtXlMeta?.name ?? "homes-location-community",
    path: indexLTaauQxtXlMeta?.path ?? "/homes/:location()/:community()",
    meta: indexLTaauQxtXlMeta || {},
    alias: indexLTaauQxtXlMeta?.alias || [],
    redirect: indexLTaauQxtXlMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: indexITBkvl0jbIMeta?.name ?? "homes-location-community-models-model",
    path: indexITBkvl0jbIMeta?.path ?? "/homes/:location()/:community()/models/:model()",
    meta: indexITBkvl0jbIMeta || {},
    alias: indexITBkvl0jbIMeta?.alias || [],
    redirect: indexITBkvl0jbIMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexuRYIOEc5ZxMeta?.name ?? "homes-location-community-plans-plan",
    path: indexuRYIOEc5ZxMeta?.path ?? "/homes/:location()/:community()/plans/:plan()",
    meta: indexuRYIOEc5ZxMeta || {},
    alias: indexuRYIOEc5ZxMeta?.alias || [],
    redirect: indexuRYIOEc5ZxMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmhViMjFSBrMeta?.name ?? "homes-location-community-qmi-home",
    path: indexmhViMjFSBrMeta?.path ?? "/homes/:location()/:community()/qmi/:home()",
    meta: indexmhViMjFSBrMeta || {},
    alias: indexmhViMjFSBrMeta?.alias || [],
    redirect: indexmhViMjFSBrMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHPO9PY7daoMeta?.name ?? "index",
    path: indexHPO9PY7daoMeta?.path ?? "/",
    meta: indexHPO9PY7daoMeta || {},
    alias: indexHPO9PY7daoMeta?.alias || [],
    redirect: indexHPO9PY7daoMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: knolls_45hoa_45informationfg3O2d8WqNMeta?.name ?? "knolls-hoa-information",
    path: knolls_45hoa_45informationfg3O2d8WqNMeta?.path ?? "/knolls-hoa-information",
    meta: knolls_45hoa_45informationfg3O2d8WqNMeta || {},
    alias: knolls_45hoa_45informationfg3O2d8WqNMeta?.alias || [],
    redirect: knolls_45hoa_45informationfg3O2d8WqNMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue").then(m => m.default || m)
  },
  {
    name: land_45acquisitionmjFMPx0ntdMeta?.name ?? "land-acquisition",
    path: land_45acquisitionmjFMPx0ntdMeta?.path ?? "/land-acquisition",
    meta: land_45acquisitionmjFMPx0ntdMeta || {},
    alias: land_45acquisitionmjFMPx0ntdMeta?.alias || [],
    redirect: land_45acquisitionmjFMPx0ntdMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: brents_45placeZLY87gxNtiMeta?.name ?? "lifestyle-brents-place",
    path: brents_45placeZLY87gxNtiMeta?.path ?? "/lifestyle/brents-place",
    meta: brents_45placeZLY87gxNtiMeta || {},
    alias: brents_45placeZLY87gxNtiMeta?.alias || [],
    redirect: brents_45placeZLY87gxNtiMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue").then(m => m.default || m)
  },
  {
    name: experience_45lower_45maintenanceoZgQmBhsGoMeta?.name ?? "lifestyle-experience-lower-maintenance",
    path: experience_45lower_45maintenanceoZgQmBhsGoMeta?.path ?? "/lifestyle/experience-lower-maintenance",
    meta: experience_45lower_45maintenanceoZgQmBhsGoMeta || {},
    alias: experience_45lower_45maintenanceoZgQmBhsGoMeta?.alias || [],
    redirect: experience_45lower_45maintenanceoZgQmBhsGoMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_93AEon2QZ3sXMeta?.name ?? "lifestyle-reviews-testimonial",
    path: _91testimonial_93AEon2QZ3sXMeta?.path ?? "/lifestyle/reviews/:testimonial()",
    meta: _91testimonial_93AEon2QZ3sXMeta || {},
    alias: _91testimonial_93AEon2QZ3sXMeta?.alias || [],
    redirect: _91testimonial_93AEon2QZ3sXMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: indexVzOzykdPKeMeta?.name ?? "lifestyle-reviews-category-category",
    path: indexVzOzykdPKeMeta?.path ?? "/lifestyle/reviews/category/:category()",
    meta: indexVzOzykdPKeMeta || {},
    alias: indexVzOzykdPKeMeta?.alias || [],
    redirect: indexVzOzykdPKeMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index6DaAJLBT3GMeta?.name ?? "lifestyle-reviews",
    path: index6DaAJLBT3GMeta?.path ?? "/lifestyle/reviews",
    meta: index6DaAJLBT3GMeta || {},
    alias: index6DaAJLBT3GMeta?.alias || [],
    redirect: index6DaAJLBT3GMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: whats_45lifefullnesssVSo9jZBKkMeta?.name ?? "lifestyle-whats-lifefullness",
    path: whats_45lifefullnesssVSo9jZBKkMeta?.path ?? "/lifestyle/whats-lifefullness",
    meta: whats_45lifefullnesssVSo9jZBKkMeta || {},
    alias: whats_45lifefullnesssVSo9jZBKkMeta?.alias || [],
    redirect: whats_45lifefullnesssVSo9jZBKkMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93x5PWtniWBKMeta?.name ?? "marshallfire-slug",
    path: _91_46_46_46slug_93x5PWtniWBKMeta?.path ?? "/marshallfire/:slug(.*)*",
    meta: _91_46_46_46slug_93x5PWtniWBKMeta || {},
    alias: _91_46_46_46slug_93x5PWtniWBKMeta?.alias || [],
    redirect: _91_46_46_46slug_93x5PWtniWBKMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: my_45favoriteslVf24aLvScMeta?.name ?? "my-favorites",
    path: my_45favoriteslVf24aLvScMeta?.path ?? "/my-favorites",
    meta: my_45favoriteslVf24aLvScMeta || {},
    alias: my_45favoriteslVf24aLvScMeta?.alias || [],
    redirect: my_45favoriteslVf24aLvScMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: new_45home_45search9Sx0rfUeKsMeta?.name ?? "new-home-search",
    path: new_45home_45search9Sx0rfUeKsMeta?.path ?? "/new-home-search",
    meta: new_45home_45search9Sx0rfUeKsMeta || {},
    alias: new_45home_45search9Sx0rfUeKsMeta?.alias || [],
    redirect: new_45home_45search9Sx0rfUeKsMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: easyhouseEC6SVyKSDpMeta?.name ?? "our-homes-home-collections-easyhouse",
    path: easyhouseEC6SVyKSDpMeta?.path ?? "/our-homes/home-collections/easyhouse",
    meta: easyhouseEC6SVyKSDpMeta || {},
    alias: easyhouseEC6SVyKSDpMeta?.alias || [],
    redirect: easyhouseEC6SVyKSDpMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: limitededitionnmuRoyMdG2Meta?.name ?? "our-homes-home-collections-limitededition",
    path: limitededitionnmuRoyMdG2Meta?.path ?? "/our-homes/home-collections/limitededition",
    meta: limitededitionnmuRoyMdG2Meta || {},
    alias: limitededitionnmuRoyMdG2Meta?.alias || [],
    redirect: limitededitionnmuRoyMdG2Meta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: wee_45cottage3Sdc5aAD6TMeta?.name ?? "our-homes-home-collections-wee-cottage",
    path: wee_45cottage3Sdc5aAD6TMeta?.path ?? "/our-homes/home-collections/wee-cottage",
    meta: wee_45cottage3Sdc5aAD6TMeta || {},
    alias: wee_45cottage3Sdc5aAD6TMeta?.alias || [],
    redirect: wee_45cottage3Sdc5aAD6TMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: index4TF32LYWOzMeta?.name ?? "our-homes",
    path: index4TF32LYWOzMeta?.path ?? "/our-homes",
    meta: index4TF32LYWOzMeta || {},
    alias: index4TF32LYWOzMeta?.alias || [],
    redirect: index4TF32LYWOzMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: our_45neighborhoodsUujp9GSZYeMeta?.name ?? "our-neighborhoods",
    path: our_45neighborhoodsUujp9GSZYeMeta?.path ?? "/our-neighborhoods",
    meta: our_45neighborhoodsUujp9GSZYeMeta || {},
    alias: our_45neighborhoodsUujp9GSZYeMeta?.alias || [],
    redirect: our_45neighborhoodsUujp9GSZYeMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue").then(m => m.default || m)
  },
  {
    name: partner_45with_45usJMCl3fxBxsMeta?.name ?? "partner-with-us",
    path: partner_45with_45usJMCl3fxBxsMeta?.path ?? "/partner-with-us",
    meta: partner_45with_45usJMCl3fxBxsMeta || {},
    alias: partner_45with_45usJMCl3fxBxsMeta?.alias || [],
    redirect: partner_45with_45usJMCl3fxBxsMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue").then(m => m.default || m)
  },
  {
    name: privacytermsF3KhGQdaMGMeta?.name ?? "privacyterms",
    path: privacytermsF3KhGQdaMGMeta?.path ?? "/privacyterms",
    meta: privacytermsF3KhGQdaMGMeta || {},
    alias: privacytermsF3KhGQdaMGMeta?.alias || [],
    redirect: privacytermsF3KhGQdaMGMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: sandboxK0aYJOVcPwMeta?.name ?? "sandbox",
    path: sandboxK0aYJOVcPwMeta?.path ?? "/sandbox",
    meta: sandboxK0aYJOVcPwMeta || {},
    alias: sandboxK0aYJOVcPwMeta?.alias || [],
    redirect: sandboxK0aYJOVcPwMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93BRNgPoytmVMeta?.name ?? "videos-slug",
    path: _91_46_46_46slug_93BRNgPoytmVMeta?.path ?? "/videos/:slug(.*)*",
    meta: _91_46_46_46slug_93BRNgPoytmVMeta || {},
    alias: _91_46_46_46slug_93BRNgPoytmVMeta?.alias || [],
    redirect: _91_46_46_46slug_93BRNgPoytmVMeta?.redirect,
    component: () => import("/codebuild/output/src3859337258/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/about-us/get-our-brochure/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/about-us/in-the-news/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/about-us/realtor-program/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/about-us/what-we-do/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/about-us/who-we-are/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/build-with-us/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/career-opportunities/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/career-opportunities/administrative-assistant-2/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/career-opportunities/director-of-warranty-services/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/career-opportunities/land-development-entitlements-coordinator/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/contact-us/ask-a-question/thank-you/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/contact-us/warranty-services/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/email-signup/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/get-updates/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/get-updates/thank-you/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/how-to-video-library/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/lifestyle/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/locations/lanterns-at-rock-creek/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/locations/silver-leaf/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/locations/wee-cottage-at-north-end-80238/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/locations/wee-cottage-at-rogers-farm/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/locations/west-edge-at-colliers-hill/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/login/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/lost-password/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/maintenance-items/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/marshall-fire-response/thank-you-loi/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/neighborhoods-map-test/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/organism-page/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/our-homes/home-collections/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/our-homes/home-collections/lanterns-series/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/our-homes/home-collections/opportunities-series/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/our-homes/photo-gallery/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/our-homes/photo-gallery/gourmet-kitchens/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/our-homes/photo-gallery/home-offices/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/our-homes/photo-gallery/living-rooms/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/our-homes/photo-gallery/outdoor-living-spaces/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/our-homes/photo-gallery/relaxing-master-suites/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/register/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/reset-password/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/resources-lower-maintenance/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/style-guide/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  },
  {
    name: component_45stubb3opEZ9TwiMeta?.name ?? undefined,
    path: component_45stubb3opEZ9TwiMeta?.path ?? "/warranty-service-request/",
    meta: component_45stubb3opEZ9TwiMeta || {},
    alias: component_45stubb3opEZ9TwiMeta?.alias || [],
    redirect: component_45stubb3opEZ9TwiMeta?.redirect,
    component: component_45stubb3opEZ9Twi
  }
]